import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Step,
  StepLabel,
  Stepper,
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography,
  TableContainer,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  handleCreatePermissionsApi,
  handleGetPermissionsApi,
  handleUpdatePermissionsApi,
} from "../../../api/roles";
import {
  PermissionRequestBody,
  PermissionsResponse,
} from "../../../api/interface";
import PermissionModal from "./PermissionModal";
import { set } from "lodash";

interface IProps {
  isNewForm: boolean;
  setIsNewForm: Dispatch<SetStateAction<boolean>>;
  setIsLoadingData: Dispatch<SetStateAction<boolean>>;
  isLoadingData: boolean;
  selectedView: string;
}
const defaultValue = {
  permissionId: 0,
  name: "",
  companyId: 0,
};

const PermissionView: React.FC<IProps> = ({
  setIsNewForm,
  isNewForm,
  isLoadingData,
  setIsLoadingData,
  selectedView,
}) => {
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  const [formData, setFormData] = useState<PermissionRequestBody>(defaultValue);
  const [permissionData, setPermissionData] = useState<PermissionsResponse[]>(
    []
  );

  const handlePermissionModalClose = () => {
    setOpenPermissionModal(false);
    setFormData(defaultValue);
    setIsNewForm(false);
  };
  const handleUpdatePermission = async () => {
    try {
      if (formData.name === "" || formData.companyId === 0) {
        alert("Please fill all fields");
        return;
      }
      const permissionId = formData.permissionId as number;
      delete formData.permissionId;
      setIsSubmittingData(true);
      await handleUpdatePermissionsApi(formData, permissionId);
      setFormData(defaultValue);
      setIsSubmittingData(false);
      handlePermissionModalClose();
    } catch (err: any) {
      setIsSubmittingData(false);
      if (err.response.status === 400) {
        alert(err.response.data.message);
      }
    }
  };
  const handleOnCreatePermission = async () => {
    try {
      if (formData.name === "" || formData.companyId === 0) {
        alert("Please fill all fields");
        return;
      }
      delete formData.permissionId;
      setIsSubmittingData(true);

      await handleCreatePermissionsApi(formData);
      setIsSubmittingData(false);
      setFormData(defaultValue);
    } catch (err: any) {
      setIsSubmittingData(false);
      if (err.response.status === 400) {
        alert(err.response.data.message);
      }
    }
  };

  const handleOnPermissionChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: key == "companyId" ? parseInt(value) : value,
    });
  };

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        setIsLoadingData(true);
        const response = await handleGetPermissionsApi();
        setPermissionData(response);
        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
        alert("Error creating permissions");
        console.log(err);
      }
    };
    fetchAccount();
  }, [selectedView, isSubmittingData]);

  const renderTableSection = () => (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Permission</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissionData.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.company.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setOpenPermissionModal(true);
                        setIsNewForm(false);
                        setFormData({
                          name: row.name,
                          companyId: row.company.id as number,
                          permissionId: row.id as number,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <Box>
      <Button
        variant="contained"
        sx={{ marginTop: 4, marginBottom: 4 }}
        onClick={() => {
          setOpenPermissionModal(true);
          setIsNewForm(true);
        }}
      >
        Add new permission
      </Button>
      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        {isLoadingData ? (
          <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {renderTableSection()}
            {openPermissionModal && (
              <PermissionModal
                openModal={openPermissionModal}
                handleClose={handlePermissionModalClose}
                handleOnChange={handleOnPermissionChange}
                handleUpdatePermission={handleUpdatePermission}
                handleOnCreatePermission={handleOnCreatePermission}
                isNewForm={isNewForm}
                formData={formData}
                setFormData={setFormData}
                isLoading={isSubmittingData}
              />
            )}
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default PermissionView;
