import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Dialog, DialogContent, DialogTitle, StepLabel } from '@mui/material';
import HealthAreaStep from './HealthAreaStep';
import ManageDiseasesStep from './ManageDiseasesStep';
import { handleCreateDiseaseBurden, IDiseaseItem } from '../../api/health_area';
import { LoadingButton } from '@mui/lab';

const steps = [
  'Health Area Category', 
  'Disease Burden', 
];

const initialFormData = {
  name: "",
  icode: "",
  id: undefined,
  diseases: [],
}

interface IBase3StepModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const Base3StepModal: React.FC<IBase3StepModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  
  const [formData, setFormData] = React.useState<{
    name: string;
    icode: string;
    id?: number;
    diseases: IDiseaseItem[];
  }>({
    name: "",
    icode: "",
    id: undefined,
    diseases: [],
  });
  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const handleNext = async () => {
    const newErrors: Record<string, string> = {};
    if (!formData.name.trim()) {
      newErrors.name = "Health Area Category Name is required";
    }
    if (!formData.icode.trim()) {
      newErrors.icode = "Health Area Category icode is required";
    }
    if (!formData.diseases.length && activeStep == 1) {
      newErrors.diseases = "At least one Disease Burden is required";
    }

    try {
      if (Object.keys(newErrors).length) {
        setErrors(newErrors);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep == 1) {
            setIsLoading(true);
            await handleCreateDiseaseBurden(formData);
            onClose();
            setIsLoading(false);
        }
      }
    } catch (err) {
      alert("Error creating disease burden");
      console.log(err);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClose = () => {
    handleClose();
    setActiveStep(0);
    setFormData(initialFormData);
  }

  const renderStep = (activeStep: number) => {
    switch(activeStep) {
      case 0:
        return (
          <HealthAreaStep
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 1:
        return (
          <ManageDiseasesStep
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 2:
        return (
          <Box sx={{marginTop: 5}}>
            <pre>{JSON.stringify(formData, null, 2)}</pre>

          </Box>
        );
    }
  }

  return (
    <Dialog
      fullScreen={false}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {steps[activeStep]}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 5, mb: 5, textAlign: 'center' }}>
                Saving...
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={onClose}>Close</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              
              {renderStep(activeStep)}

              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <LoadingButton
                  sx={{ marginTop: 5 }}
                  disableElevation
                  loadingPosition="center"
                  loading={isLoading}
                  variant="text"
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </LoadingButton>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Base3StepModal;
