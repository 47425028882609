import { IRoles } from "../components/Roles/interface";
import {
  ApiResponse,
  ICreateUpdateRole,
  IPermissionByCompanyIdResponse,
  PermissionRequestBody,
  PermissionsResponse,
} from "./interface";
import { axiosInstanceAuth } from "./setup";

export const handleGetPermissionByCompanyIdApi = (
  companyId: number
): Promise<IPermissionByCompanyIdResponse[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get(`/admin/permission_by_company/${companyId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleCreateRolesApi = (
  formData: ICreateUpdateRole
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/create_role`, formData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const handleUpdateRolesApi = (
  formData: ICreateUpdateRole
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .put(`/admin/update_role`, formData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetPermissionsApi = (): Promise<PermissionsResponse[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get(`/admin/permissions`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleCreatePermissionsApi = (
  formData: PermissionRequestBody
): Promise<PermissionsResponse[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/permission`, formData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdatePermissionsApi = (
  formData: PermissionRequestBody,
  permissionId: number
): Promise<PermissionsResponse[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .put(`/admin/permission/${permissionId}`, formData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetRoles = (): Promise<IRoles[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get<ApiResponse>(`/admin/fetch_roles`)
      .then((result) => {
        resolve(result.data.data as IRoles[]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetRolesByCompanyIdApi = (
  companyId: number
): Promise<IRoles[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get<ApiResponse>(`/admin/roles_by_company?companyId=${companyId}`)
      .then((result) => {
        resolve(result.data.data as IRoles[]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
