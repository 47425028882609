import { LoadingButton, TabPanel } from "@mui/lab";
import {
  Button,
  TableContainer,
  Paper,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ButtonProps,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  handleCreateAccountApi,
  handleDeleteAccountApi,
  handleGetAccountsApi,
  handleUpdateAccountApi,
} from "../../api/accounts";
import { useAppSelector } from "../../store/reduxHooks";
import { getCookie, removeCookie } from "../../utils/cookie";
import {
  EFormAction,
  IAddAccountRequest,
  UserResponse,
} from "../../api/interface";
import { useNavigate } from "react-router-dom";
import UserModal from "./UserModal";
import { emailTemplate } from "../../utils/constants";

interface IProps {
  value: string;
}

const AuthCustomButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
  borderRadius: 5,
  marginTop: 14,
  fontSize: 15,
  textTransform: "none",
  backgroundColor: "#00B196",
}));

const defaultFormData = {
  action: EFormAction.CREATE,
  firstName: "",
  lastName: "",
  email: "",
  companyId: 0,
  roleId: 0,
  departmentId: null,
  templateId: emailTemplate.addAccount,
};

const UserComponent: React.FC<IProps> = ({ value }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const user = useAppSelector((state) => state.userSlice.user);
  const [isLoading, setIsLoading] = useState(false);
  const [formAction, setFormAction] = useState<EFormAction>(EFormAction.CREATE);
  const [formData, setFormData] = useState<IAddAccountRequest>(
    {} as IAddAccountRequest
  );
  const [accountData, setAccountData] = useState<UserResponse[]>([]);

  const navigate = useNavigate();

  const handleClose = () => {
    setOpenModal(false);
    setFormData(defaultFormData);
    setFormAction(EFormAction.CREATE);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setFormAction(EFormAction.CREATE);
    setFormData(defaultFormData);
  };

  const handleOpenEditModal = (userData: UserResponse) => {
    setOpenModal(true);
    setFormAction(EFormAction.UPDATE);
    setFormData({
      id: userData.id,
      action: EFormAction.UPDATE,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      companyId: userData.company.id as number,
      roleId: userData.roleId,
      departmentId: userData.departmentId,
      templateId: emailTemplate.addAccount,
    });
  };

  const handleDelete = async (id: number) => {
    try {
      const value = window.confirm("Do you really want to do this?");
      if (value) {
        setIsLoading(true);
        await handleDeleteAccountApi(id);
        setAccountData(accountData.filter((data) => data.id !== id));
      }

      setIsLoading(false);
    } catch (err) {
      alert("Error deleting role");
      console.log(err);
    }
  };

  const handleUpdateUser = async () => {
    try {
      setIsLoading(true);
      delete formData.action;
      delete formData.templateId;
      formData.companyId = parseInt(formData.companyId as string);
      formData.departmentId =
        formData.departmentId == 0
          ? null
          : parseInt(formData.departmentId as string);
      formData.roleId = parseInt(formData.roleId as string);

      await handleUpdateAccountApi(formData);

      handleClose();
      setIsLoading(false);
    } catch (err) {
      alert("Error creating: user might already exist");
      console.log(err);
    }
  };
  const handleCreateUser = async () => {
    try {
      setIsLoading(true);
      delete formData.action;
      delete formData.id;
      formData.companyId = parseInt(formData.companyId as string);
      formData.departmentId = formData.departmentId
        ? null
        : parseInt(formData.departmentId as string);
      formData.roleId = parseInt(formData.roleId as string);

      await handleCreateAccountApi(formData);

      handleClose();
      setIsLoading(false);
    } catch (err) {
      alert("Error creating: user might already exist");
      console.log(err);
    }
  };

  const handleOnChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: key == "companyId" ? parseInt(value) : value,
    });
  };

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        setIsLoadingData(true);
        const response = await handleGetAccountsApi();
        setAccountData(response);

        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
        console.log(err);
      }
    };
    if (user) {
      fetchAccount();
    }
  }, [user, getCookie(), isLoading]);

  const renderTableSection = () => (
    <Table aria-label="simple table" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>First name</TableCell>
          <TableCell align="center">Last name</TableCell>
          <TableCell align="center">Email</TableCell>
          <TableCell align="center">Company</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accountData.map((row, index) => (
          <TableRow
            key={index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.firstName}
            </TableCell>
            <TableCell align="center">{row.lastName}</TableCell>
            <TableCell align="center">{row.email}</TableCell>
            <TableCell align="center">{row.company.name}</TableCell>
            <TableCell align="center">
              <AuthCustomButton
                variant="contained"
                type="submit"
                loadingPosition="end"
                disableRipple
                onClick={() => handleOpenEditModal(row)}
                sx={{
                  background: "#ffd740",
                  width: 76,
                  height: 32,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#ffd740",
                  },
                }}
              >
                Edit
              </AuthCustomButton>

              <AuthCustomButton
                variant="contained"
                type="submit"
                loadingPosition="end"
                disableRipple
                onClick={() => handleDelete(row.id)}
                sx={{
                  background: "#f44336",
                  width: 76,
                  height: 32,
                  marginLeft: "8px",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "#f44336",
                  },
                }}
              >
                Delete
              </AuthCustomButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <TabPanel value={value}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Button
            variant="contained"
            sx={{ marginTop: 4, marginBottom: 4 }}
            onClick={handleOpenModal}
          >
            Add account
          </Button>
        </Box>

        <Button
          sx={{ marginTop: 4, marginBottom: 4 }}
          onClick={() => {
            removeCookie();
            navigate("/");
          }}
        >
          Log out
        </Button>
      </Box>
      {openModal && (
        <UserModal
          openModal={openModal}
          handleClose={handleClose}
          formAction={formAction}
          handleUpdateUser={handleUpdateUser}
          handleCreateUser={handleCreateUser}
          isLoading={isLoading}
          formData={formData}
          handleOnChange={handleOnChange}
        />
      )}

      <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
        {isLoadingData ? (
          <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          renderTableSection()
        )}
      </TableContainer>
    </TabPanel>
  );
};

export default UserComponent;
