// EditHealthCategoryModal.tsx

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Alert,
} from "@mui/material";
import HealthAreaStep from "./HealthAreaStep";
import {
  IHealthAreaItem,
  handleUpdateHealthAreaCategory,
} from "../../api/health_area";

interface EditHealthCategoryModalProps {
  healthAreaToEdit?: IHealthAreaItem;
  isOpen: boolean;
  handleClose: () => void;
  onUpdate: () => void;
}

const EditHealthCategoryModal: React.FC<EditHealthCategoryModalProps> = ({
  healthAreaToEdit,
  isOpen,
  handleClose,
  onUpdate,
}) => {
  const [formData, setFormData] = useState<any>({
    name: "",
    icode: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);

  useEffect(() => {
    if (healthAreaToEdit) {
      setFormData({
        name: healthAreaToEdit.name || "",
        icode: healthAreaToEdit.icode || "",
      });
      setErrors({});
      setSubmissionError(null);
    }
  }, [healthAreaToEdit]);

 
  const handleSubmit = async () => {
   
    const newErrors: Record<string, string> = {};
    if (!formData.name || formData.name.trim().length < 2) {
      newErrors.name = "Name must be at least 2 characters.";
    }
    if (!formData.icode || formData.icode.trim().length < 2) {
      newErrors.icode = "Icode must be at least 2 characters.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);
    setSubmissionError(null);

    try {
     
      await handleUpdateHealthAreaCategory(healthAreaToEdit!.id, formData.name, formData.icode);
      setIsSubmitting(false);
      onUpdate();
      handleClose();
    } catch (error: any) {
      console.error("Error updating health area:", error);
      setSubmissionError(
        error?.response?.data?.message || "Failed to update health area."
      );
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Health Area</DialogTitle>
      <DialogContent>
        {submissionError && (
          <Box mb={2}>
            <Alert severity="error">{submissionError}</Alert>
          </Box>
        )}
        <HealthAreaStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          {isSubmitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHealthCategoryModal;
