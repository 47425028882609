import { ApiResponse, DepartmentResponse } from "./interface";
import { axiosInstanceAuth } from "./setup";

export const handleGetDepartments = (): Promise<DepartmentResponse[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get<ApiResponse>(`/admin/fetch_departments`)
      .then((result) => {
        resolve(result.data.data as DepartmentResponse[]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDepartmentsByCompanyApi = (
  companyId: number
): Promise<DepartmentResponse[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get<ApiResponse>(`/admin/department_by_company?companyId=${companyId}`)
      .then((result) => {
        resolve(result.data.data as DepartmentResponse[]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
