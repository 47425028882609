import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Step,
  StepLabel,
  Stepper,
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useAppSelector } from "../../store/reduxHooks";
import {
  ICreateUpdateRole,
  IPermissionByCompanyIdResponse,
} from "../../api/interface";
import { handleGetPermissionByCompanyIdApi } from "../../api/roles";
import PermissionComponent from "./PermissionComponent";

interface IProps {
  openModal: boolean;
  handleClose: () => void;
  handleOnChange: (key: string, value: string) => void;
  handleOnUpdateRole: () => void;
  handleOnCreateRole: () => void;
  isNewForm: boolean;
  isLoading: boolean;
  formData: ICreateUpdateRole;
  setFormData: Dispatch<SetStateAction<ICreateUpdateRole>>;
}

const steps = ["Create a role", "Assign Permissions"];

const RolesModal: React.FC<IProps> = ({
  openModal,
  handleClose,
  handleOnChange,
  handleOnUpdateRole,
  handleOnCreateRole,
  isNewForm,
  formData,
  setFormData,
  isLoading,
}) => {
  const companyData = useAppSelector((state) => state.companySlice.companyData);
  const [activeStep, setActiveStep] = React.useState(0);
  const [permissionData, setPermissionData] = React.useState<
    IPermissionByCompanyIdResponse[]
  >([]);

  useEffect(() => {
    if (activeStep === 1) {
      const handleGetPermissionByCompanyId = async () => {
        try {
          const [permissions] = await Promise.all([
            await handleGetPermissionByCompanyIdApi(formData.companyId),
          ]);

          //get roles if in edit mode and set the selected roles
          setPermissionData(permissions);
        } catch (err) {
          console.log(err);
        }
      };
      handleGetPermissionByCompanyId();
    }
  }, [formData.companyId, activeStep]);

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            <Box sx={{ minWidth: 120, marginTop: 5 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Select a company
                </InputLabel>
                <NativeSelect
                  value={formData.companyId}
                  onChange={(e) => {
                    handleOnChange("companyId", e.target.value as string);
                  }}
                  inputProps={{
                    name: "companyId",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value={0}>Select a company</option>
                  {companyData &&
                    companyData.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
            </Box>
            <TextField
              sx={{
                marginTop: 5,
                marginBottom: 5,
              }}
              id="roleId"
              name="roleName"
              label="Role Name"
              variant="outlined"
              fullWidth
              value={formData.roleName}
              onChange={(e) => handleOnChange("roleName", e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
        );
      case 1:
        return (
          <Box sx={{ marginTop: 5 }}>
            <Typography>Select roles for {formData.roleName}</Typography>
            <PermissionComponent
              permissionData={permissionData}
              formData={formData}
              setFormData={setFormData}
              isNewForm={isNewForm}
            />
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="update-roles-modal-title"
      aria-describedby="update-roles-modal-description"
    >
      <DialogTitle id="update-roles-modal-title">
        {isNewForm ? "Create Role" : "Update Role"}
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Stepper activeStep={1}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent()}
      </DialogContent>
      <DialogActions>
        {activeStep == 0 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (formData.roleName.length > 0) {
                setActiveStep(1);
              } else {
                alert("Role name is required");
              }
            }}
          >
            Next
          </Button>
        ) : (
          <Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setActiveStep(0)}
              sx={{ marginRight: 2 }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={isNewForm ? handleOnCreateRole : handleOnUpdateRole}
              disabled={isLoading}
            >
              {isNewForm ? "Create" : "Update"}
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RolesModal;
