import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

// Styled component for action buttons
export const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 5,
  textTransform: "none",
  marginRight: theme.spacing(1),
  width: 100,
  height: 32,
}));
