import { AxiosResponse } from "axios";
import { axiosInstanceAuth } from "./setup";
import { IRoles } from "../components/Roles/interface";
import { ApiResponse, IAddAccountRequest, UserResponse } from "./interface";

export const handleGetAccountsApi = (): Promise<UserResponse[]> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get(`/admin/fetch_users`)
      .then((result) => {
        resolve(result.data.data as UserResponse[]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const sendOTPForgetPasswordApi = (
  email: string
): Promise<AxiosResponse<void>> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/send_otp_account`, {
        email: email,
        templateId: "d-e5fda7b8f91641539e1e5c40c34dfe50",
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        console.log(err, "err");
        reject(err);
      });
  });
};

export const verifyAndUpdatePasswordAPI = (data: {
  email: string;
  otp: string;
  password: string;
}): Promise<AxiosResponse<Response>> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/verify_and_update_password`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetCompanies = (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get(`/admin/fetch_companies`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const handleGetCountries = (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get(`/admin/fetch_countries`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleCreateAccountApi = (
  formData: IAddAccountRequest
): Promise<IAddAccountRequest> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/create_account`, formData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdateAccountApi = (
  formData: IAddAccountRequest
): Promise<IAddAccountRequest> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .put(`/admin/update_account`, formData)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteAccountApi = (id: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/delete_account`, { id: id })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteDepartment = (id: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    axiosInstanceAuth()
      .delete("/admin/delete_department", {
        data: { id: id },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const handleDeleteCountry = (id: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .delete(`/admin/delete_country`, { data: { id: id } })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteRole = (id: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .delete(`/admin/delete_role`, { data: { id: id } })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleCreateNewCompany = (name: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/create_company`, { name })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdateCompany = (id: string, name: string): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/update_company?id=${id}`, {
        name,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleCreateNewCountry = (fields: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/create_country`, { ...fields })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdateCountry = (fields: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .put("/admin/update_country", { ...fields })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const handleCreateNewEntity = (
  type: string,
  fields: any
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/create_${type}`, { ...fields })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdateEntity = (type: string, fields: any): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .put(`/admin/update_${type}`, { ...fields })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleGetDepartmentsByCompanyId = (
  company_id: string | number
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get(`/admin/fetch_departments?company_id=${company_id}`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
