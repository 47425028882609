import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
} from "@mui/material";
import { IDiseaseItem, IHealthAreaItem } from "../../api/health_area";
import { ActionButton } from "../ActionButton";

interface DiseaseBurdensTableProps {
  isLoading: boolean;
  diseasesData: IDiseaseItem[];
  healthAreas: IHealthAreaItem[];
  handleEdit: (disease: IDiseaseItem) => void;
}

interface IHealthAreaMapper {
  [key: number]: string;
}

const DiseaseBurdensTable: React.FC<DiseaseBurdensTableProps> = ({
  isLoading,
  diseasesData,
  healthAreas,
  handleEdit,
}) => {

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress />
      </div>
    );
  }

  if (diseasesData.length === 0) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <p>No Diseases Available</p>
      </div>
    );
  }

  // Fixing healthAreaMapper implementation
  const healthAreaMapper = healthAreas.reduce((acc: IHealthAreaMapper, cur: IHealthAreaItem) => {
    acc[cur.id] = cur.name;
    return acc;
  }, {} as IHealthAreaMapper);

  return (
    <Table aria-label="health area table" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell><strong>Name</strong></TableCell>
          <TableCell><strong>Icode</strong></TableCell>
          <TableCell><strong>Health Area Category</strong></TableCell>
          <TableCell><strong>Source</strong></TableCell>
          <TableCell><strong>Source Year</strong></TableCell>
          <TableCell><strong>Data Type</strong></TableCell>
          <TableCell><strong>Filters</strong></TableCell>
          <TableCell align="center"><strong>Actions</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {diseasesData.map((disease) => (
          <TableRow key={disease.id}>
            <TableCell>{disease?.name}</TableCell>
            <TableCell>{disease.icode}</TableCell>
            <TableCell>{healthAreaMapper[disease.healthAreaCategoryId]}</TableCell>
            <TableCell>{disease.source}</TableCell>
            <TableCell>{disease.sourceYear}</TableCell>
            <TableCell>{disease.dataType}</TableCell>
            <TableCell>
              {disease.filters?.measure_type && 'Measure Type'}
              {disease.filters?.race && ' Race'}
              {disease.filters?.sex && ' Sex'}
              {disease.filters?.age && ' Age'}
            </TableCell>
            <TableCell align="center">
              <ActionButton
                variant="contained"
                color="primary"
                onClick={() => handleEdit(disease)}
              >
                Edit
              </ActionButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default DiseaseBurdensTable;
