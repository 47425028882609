import {
  Box,
  TextField,
} from "@mui/material";
import * as React from "react";

interface IAddAddHealthAreaModal {
  formData: React.SetStateAction<any>;
  setFormData: React.SetStateAction<any>;
  errors: Record<string, string>;
  setErrors: React.SetStateAction<any>;
}

const HealthAreaStep: React.FC<IAddAddHealthAreaModal> = ({
  formData,
  setFormData,
  errors,
  setErrors,
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: {}) => ({ ...prev, [name]: value }));
    setErrors((prev: {}) => ({ ...prev, [name]: "" }));
  };

  return (
    <Box
      component="form"
      sx={{
        marginTop: 5
      }}
    >
      <TextField
        label="Name"
        id="name"
        fullWidth
        type="text"
        value={formData.name}
        name="name"
        required
        onChange={handleOnChange}
        error={Boolean(errors.name)}
        sx={{ marginBottom: 5 }}
        inputProps={{
          minLength: 2,
          maxLength: 100
        }}
      />
      <TextField
        label="icode"
        id="icode"
        fullWidth
        type="text"
        value={formData.icode}
        name="icode"
        required
        onChange={handleOnChange}
        error={Boolean(errors.icode)}
        sx={{ marginBottom: 5 }}
        inputProps={{
          minLength: 2,
          maxLength: 100
        }}
      />
    </Box>
  );
};

export default HealthAreaStep;