import React, { useEffect, useState } from "react";
import { TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
} from "@mui/material";
import HealthAreaTable from "./HealthAreaTable";
import DiseaseBurdensTable from "./DiseaseBurdensTable";
import { getCookie } from "../../utils/cookie";
import { useAppSelector } from "../../store/reduxHooks";
import {
  handleGetDiseaseBurdens,
  handleGetHealthAreaCategories,
  handlePublishHealthAreaCategory,
  IDiseaseItem,
  IHealthAreaItem,
} from "../../api/health_area";
import Base3StepModal from "./Base3StepModal";
import EditHealthCategoryModal from "./EditHealthCategoryModal";
import EditDiseaseModal from "./EditDiseaseModal"

interface IHealthAreaProps {
  value: string;
}

const HealthAreaComponent: React.FC<IHealthAreaProps> = ({ value }) => {
  const user = useAppSelector((state) => state.userSlice.user);

  const [selectedView, setSelectedView] = useState('health_area');
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [healthAreaToEdit, setHealthAreaToEdit] = useState<IHealthAreaItem>();
  const [diseaseToEdit, setDiseaseToEdit] = useState<IDiseaseItem>();
  const [healthAreas, setHealthAreas] = useState<IHealthAreaItem[]>([]);
  const [diseaseBurdens, setDiseaseBurdens] = useState<IDiseaseItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleViewChange = (viewName: string) => {
    setSelectedView(viewName);
  };

  const fetchHealthAreaData = async () => {
    try {
      setIsLoadingData(true);

      const [
        categories, 
        diseases, 
      ] = await Promise.all([
        handleGetHealthAreaCategories(),
        handleGetDiseaseBurdens(),
      ]);

      setHealthAreas(categories.data.data);
      setDiseaseBurdens(diseases.data.data);

      setIsLoadingData(false);
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (user) {
      fetchHealthAreaData();
    }
  }, [user, getCookie()]);
  
  const handleEditHealthArea = (healthArea: IHealthAreaItem) => {
    setHealthAreaToEdit(healthArea);
  };
  
  const handleEditDisease = (disease: IDiseaseItem) => {
    setDiseaseToEdit(disease);
  };

  const handleTogglePublish = (id: number, isPublished: boolean) => {
    handlePublishHealthAreaCategory(id)
      .then(() => {
        fetchHealthAreaData();
      }).catch((error) => {
        console.log(error, 'error')
      })
  };

  return (
    <TabPanel value={value}>
      <Button
        variant="contained"
        sx={{ marginTop: 4, marginBottom: 4 }}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Add new
      </Button>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box>
          <ButtonGroup>
            <Button
              onClick={() => handleViewChange("health_area")}
              sx={{
                backgroundColor:
                  selectedView === "health_area" ? "primary.main" : "grey.300",
                color: selectedView === "health_area" ? "white" : "black",
              }}
            >
              Health Area Categories
            </Button>
            <Button
              onClick={() => handleViewChange("disease_burdens")}
              sx={{
                backgroundColor:
                  selectedView === "disease_burdens"
                    ? "primary.main"
                    : "grey.300",
                color: selectedView === "disease_burdens" ? "white" : "black",
              }}
            >
              Disease Burdens
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      
      {selectedView == 'health_area' ? (
        <HealthAreaTable
          isLoading={isLoadingData}
          tableData={healthAreas}
          handleEdit={handleEditHealthArea}
          handleTogglePublish={handleTogglePublish}
        />
      ) : (
        <DiseaseBurdensTable
          isLoading={isLoadingData}
          diseasesData={diseaseBurdens}
          healthAreas={healthAreas}
          handleEdit={handleEditDisease}
        />
      )}

      <EditHealthCategoryModal
        healthAreaToEdit={healthAreaToEdit}
        isOpen={!!healthAreaToEdit}
        handleClose={() => { setHealthAreaToEdit(undefined) }}
        onUpdate={fetchHealthAreaData}
      />

      <EditDiseaseModal
        healthAreas={healthAreas}
        diseaseToEdit={diseaseToEdit}
        isOpen={!!diseaseToEdit}
        handleClose={() => { setDiseaseToEdit(undefined) }}
        onUpdate={fetchHealthAreaData}
      />

      <Base3StepModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </TabPanel>
  );
};

export default HealthAreaComponent;
