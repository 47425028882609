import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Switch,
} from "@mui/material";
import { handlePublishHealthAreaCategory, IHealthAreaItem } from "../../api/health_area";
import { ActionButton } from "../ActionButton";

// Define the props for HealthAreaTable
interface HealthAreaTableProps {
  isLoading: boolean;
  tableData: IHealthAreaItem[];
  handleEdit: (healthArea: IHealthAreaItem) => void;
  handleTogglePublish: (id: number, isPublished: boolean) => void;
}

const HealthAreaTable: React.FC<HealthAreaTableProps> = ({
  isLoading,
  tableData,
  handleEdit,
  handleTogglePublish
}) => {

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress />
      </div>
    );
  }

  if (tableData.length === 0) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <p>No Health Areas Available</p>
      </div>
    );
  }

  return (
    <Table aria-label="health area table" stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell><strong>Name</strong></TableCell>
          <TableCell><strong>Icode</strong></TableCell>
          <TableCell align="center"><strong>Actions</strong></TableCell>
          <TableCell align="center"><strong>Published</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData?.map((healthArea) => {
          const edit = () => {
            handleEdit(healthArea)
          }

          const togglePublish = () => {
            handleTogglePublish(healthArea.id, !!healthArea.isPublished)
          }

          return (
            <TableRow key={healthArea.id}>
              <TableCell>{healthArea.name}</TableCell>
              <TableCell>{healthArea.icode}</TableCell>
              <TableCell align="center">
                <ActionButton
                  variant="contained"
                  color="primary"
                  onClick={edit}
                >
                  Edit
                </ActionButton>
              </TableCell>
              <TableCell align="center">
                <Switch
                    checked={healthArea.isPublished}
                    onChange={togglePublish}
                    defaultChecked={healthArea.isPublished}
                  />
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  );
};

export default HealthAreaTable;