import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ICompanyData {
  id: number;
  name: string;
}

const initialState = {
  companyData: [] as ICompanyData[],
};

export const companySlice = createSlice({
  name: "companySlice",
  initialState,
  reducers: {
    setCompanyData: (state, action: PayloadAction<ICompanyData[]>) => {
      state.companyData = action.payload;
    },
  },
});

export const { setCompanyData } = companySlice.actions;

export default companySlice.reducer;
