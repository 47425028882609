import { TabPanel } from "@mui/lab";
import {
  Button,
  TableContainer,
  Paper,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/reduxHooks";
import { getCookie } from "../../utils/cookie";
import { IPermissionObj, IRoles } from "./interface";
import RolesModal from "./RolesModal";
import { ICreateUpdateRole } from "../../api/interface";
import {
  handleCreateRolesApi,
  handleGetRoles,
  handleUpdateRolesApi,
} from "../../api/roles";
import PermissionView from "./PermissionView";

interface IProps {
  value: string;
}

const defaultFormData = {
  roleName: "",
  companyId: 0,
  permissionsObj: [],
};

const RolesComponent: React.FC<IProps> = ({ value }) => {
  const [open, setOpenModal] = React.useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [roles, setRoles] = useState<IRoles[]>([]);
  const user = useAppSelector((state) => state.userSlice.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [formData, setFormData] = useState<ICreateUpdateRole>(defaultFormData);
  const [selectedView, setSelectedView] = useState("roles_view");

  const handleViewChange = (view: string) => {
    setSelectedView(view);
  };

  const handleClose = () => {
    setOpenModal(false);
    setFormData(defaultFormData);
  };

  const handleOnUpdateRole = async () => {
    try {
      setIsLoading(true);

      await handleUpdateRolesApi(formData);
      setIsLoading(false);
      setOpenModal(false);
      setFormData(defaultFormData);
    } catch (err) {
      alert("Error creating role");
      console.log(err);
    }
  };

  const handleOnCreateRole = async () => {
    try {
      setIsLoading(true);
      await handleCreateRolesApi(formData);
      setIsLoading(false);
      setOpenModal(false);
      setFormData(defaultFormData);
    } catch (err) {
      alert("Error creating role");
      console.log(err);
    }
  };

  const handleOnChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      [key]: key == "companyId" ? parseInt(value) : value,
    });
  };

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        setIsLoadingData(true);
        const roles = await handleGetRoles();
        setRoles(roles);

        setIsLoadingData(false);
      } catch (err) {
        setIsLoadingData(false);
        console.log(err);
      }
    };
    if (user) {
      fetchAccount();
    }
  }, [user, getCookie(), isLoading]);

  const renderPermission = (permissionsObj: IPermissionObj[]) => {
    return permissionsObj.map((result) => result.permissionName).join(", ");
  };

  const renderTableSection = () => (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Role Name</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Permissions</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.company.name}</TableCell>
                  {row.permissionsObj ? (
                    <TableCell>
                      {renderPermission(row.permissionsObj)}
                    </TableCell>
                  ) : (
                    <TableCell>{"None assinged"}</TableCell>
                  )}

                  <TableCell>
                    <Button
                      onClick={() => {
                        setOpenModal(true);
                        setIsNewForm(false);
                        setFormData({
                          roleName: row.name,
                          companyId: row.company.id,
                          permissionsObj: row.permissionsObj,
                          roleId: row.id,
                        });
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const renderButtonGroupView = () => {
    return (
      <Box>
        <ButtonGroup>
          <Button
            onClick={() => handleViewChange("roles_view")}
            sx={{
              backgroundColor:
                selectedView === "roles_view" ? "primary.main" : "grey.300",
              color: selectedView === "roles_view" ? "white" : "black",
            }}
          >
            Roles
          </Button>
          <Button
            onClick={() => handleViewChange("permissions_view")}
            sx={{
              backgroundColor:
                selectedView === "permissions_view"
                  ? "primary.main"
                  : "grey.300",
              color: selectedView === "permissions_view" ? "white" : "black",
            }}
          >
            Permissions
          </Button>
        </ButtonGroup>
      </Box>
    );
  };

  const renderView = () => {
    if (selectedView === "roles_view") {
      return (
        <Box>
          <Button
            variant="contained"
            sx={{ marginTop: 4, marginBottom: 4 }}
            onClick={() => {
              setOpenModal(true);
              setIsNewForm(true);
            }}
          >
            Add new Role
          </Button>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            {isLoadingData ? (
              <Box sx={{ textAlign: "center", marginTop: 5, marginBottom: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                {renderTableSection()}
                {open && (
                  <RolesModal
                    openModal={open}
                    handleClose={handleClose}
                    handleOnChange={handleOnChange}
                    handleOnUpdateRole={handleOnUpdateRole}
                    handleOnCreateRole={handleOnCreateRole}
                    isNewForm={isNewForm}
                    formData={formData}
                    setFormData={setFormData}
                    isLoading={isLoading}
                  />
                )}
              </Box>
            )}
          </TableContainer>
        </Box>
      );
    } else {
      return (
        <PermissionView
          isNewForm={isNewForm}
          setIsNewForm={setIsNewForm}
          isLoadingData={isLoadingData}
          setIsLoadingData={setIsLoadingData}
          selectedView={selectedView}
        />
      );
    }
  };

  return (
    <TabPanel value={value}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {renderButtonGroupView()}
      </Box>
      {renderView()}
    </TabPanel>
  );
};

export default RolesComponent;
