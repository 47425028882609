import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import {
  ICreateUpdateRole,
  IPermissionByCompanyIdResponse,
} from "../../api/interface";
import { Dispatch, SetStateAction, useEffect } from "react";
import { IPermissionObj } from "./interface";

interface IProps {
  permissionData: IPermissionByCompanyIdResponse[];
  formData: ICreateUpdateRole;
  setFormData: Dispatch<SetStateAction<ICreateUpdateRole>>;
  isNewForm: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  name: string,
  selectedPersmission: readonly string[],
  theme: Theme
) {
  return {
    fontWeight: selectedPersmission.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const PermissionComponent: React.FC<IProps> = ({
  permissionData,
  formData,
  setFormData,
  isNewForm,
}) => {
  const theme = useTheme();
  const [selectedPersmission, setSelectedPersmission] = React.useState<
    string[]
  >([]);

  const handleChange = (
    event: SelectChangeEvent<typeof selectedPersmission>
  ) => {
    const copy = { ...formData };
    const {
      target: { value },
    } = event;
    setSelectedPersmission(
      typeof value === "string" ? value.split(",") : value
    );
    const permissionObjects: IPermissionObj[] = permissionData
      .filter((permission) => value.includes(permission.name))
      .map((permission) => ({
        permissionId: permission.id,
        permissionName: permission.name,
      }));

    copy.permissionsObj = permissionObjects;
    setFormData(copy);
  };

  useEffect(() => {
    if (!isNewForm) {
      const permissionNames: string[] = permissionData
        .filter(
          (permission) =>
            formData.permissionsObj &&
            formData.permissionsObj.some(
              (selected) => selected.permissionId === permission.id
            )
        )
        .map((permission) => permission.name);

      setSelectedPersmission(permissionNames);
    }
  }, [isNewForm, permissionData]);

  return (
    <div>
      <FormControl sx={{ m: 1, width: "100%", marginTop: 4 }}>
        <InputLabel id="permission-multiple-chip-label">
          Permission List
        </InputLabel>
        <Select
          labelId="permission-multiple-chip-label"
          id="permission-multiple-chip"
          multiple
          value={selectedPersmission}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {permissionData.map((result, index) => (
            <MenuItem
              key={result.id}
              value={result.name}
              style={getStyles(result.name, selectedPersmission, theme)}
            >
              {result.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default PermissionComponent;
