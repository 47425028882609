import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Step,
  StepLabel,
  Stepper,
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect } from "react";

import { useAppSelector } from "../../../store/reduxHooks";
import { PermissionRequestBody } from "../../../api/interface";

interface IProps {
  openModal: boolean;
  handleClose: () => void;
  handleOnChange: (key: string, value: string) => void;
  handleUpdatePermission: () => void;
  handleOnCreatePermission: () => void;
  isNewForm: boolean;
  isLoading: boolean;
  formData: PermissionRequestBody;
  setFormData: Dispatch<SetStateAction<PermissionRequestBody>>;
}

const RolesModal: React.FC<IProps> = ({
  openModal,
  handleClose,
  handleOnChange,
  handleUpdatePermission,
  handleOnCreatePermission,
  isNewForm,
  formData,
  setFormData,
  isLoading,
}) => {
  const companyData = useAppSelector((state) => state.companySlice.companyData);

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="update-company-modal-title"
      aria-describedby="update-company-modal-description"
    >
      <DialogTitle id="update-company-modal-title">
        {isNewForm ? "Create permissions" : "Update Permissions"}
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Box sx={{ minWidth: 120, marginTop: 5 }}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Select a company
            </InputLabel>
            <NativeSelect
              value={formData.companyId}
              onChange={(e) => {
                handleOnChange("companyId", e.target.value as string);
              }}
              inputProps={{
                name: "companyId",
                id: "uncontrolled-native",
              }}
            >
              <option value={0}>Select a company</option>
              {companyData.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Box>

        <TextField
          sx={{
            marginTop: 5,
            marginBottom: 5,
          }}
          id="permissionName"
          name="permissionName"
          label="Permission Name"
          variant="outlined"
          fullWidth
          value={formData.name}
          onChange={(e) => handleOnChange("name", e.target.value)}
          inputProps={{ maxLength: 100 }}
        />
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={
              isNewForm ? handleOnCreatePermission : handleUpdatePermission
            }
            disabled={isLoading}
          >
            {isNewForm ? "Create" : "Update"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RolesModal;
